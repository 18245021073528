import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

// import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-ribbon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ribbon.component.html',
  styleUrl: './ribbon.component.scss',
})
export class RibbonComponent {
  @Input() environment!: any;
  @Input() position = 'bottom-left';
  @Input() color = 'green';
  tag!: string;
  showRibbon: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.tag = this.environment.demo ? 'DEMO' : 'DEV';
    this.showRibbon = !this.environment.production;
  }
}
